<div class="container">
  <ng-container *ngFor="let block of parsedContent.blocks">
    <ng-container [ngSwitch]="block.type">
      <thema-core-editable-table
        *ngSwitchCase="'table'"
        [headers]="block.data.content[0]"
        [customLayoutData]="block.data.content | slice : 1"
      >
      </thema-core-editable-table>
      <details thema-core-expansion *ngSwitchCase="'expansion'">
        <summary>{{ block.data.summaryInnerText }}</summary>
        <p>{{ block.data.paragraphInnerText }}</p>
      </details>

      <header *ngSwitchCase="'header'">
        <h1
          *ngIf="block.data.level === 1; else h2"
          class="h1"
          [innerHTML]="block.data.text | purify"
        ></h1>

        <ng-template #h2
          ><h2 class="h2" [innerHTML]="block.data.text | purify"></h2
        ></ng-template>
      </header>

      <p
        *ngSwitchCase="'paragraph'"
        [style.textAlign]="block.tunes?.alignmentTunes?.align"
        [innerHTML]="block.data.text | purify:SecurityContext.HTML:true"
      ></p>

      <img
        *ngSwitchCase="'image'"
        [class.stretched]="block.data.stretched"
        [class.alignedLeft]="block.data.align_left"
        [appLazyload]="block.data.file.url"
        alt=""
      />

      <thema-core-image-with-text
        *ngSwitchCase="'imageWithBlock'"
        [data]="block.data"
      ></thema-core-image-with-text>

      <a
        *ngSwitchCase="'buttonLink'"
        thema-core-button
        [class]="'align-' + block.tunes?.alignmentTunes?.align"
        [routerLink]="block.data.routerLink"
        >{{ block.data.innerText }}</a
      >

      <thema-core-contact-expert
        *ngSwitchCase="'contactButton'"
        [class]="'align-' + block.tunes?.alignmentTunes?.align"
      ></thema-core-contact-expert>

      <ng-container *ngSwitchCase="'list'">
        <ul *ngIf="block.data.style === 'unordered'; else ordered">
          <li *ngFor="let item of block.data.items" [innerHTML]="item"></li>
        </ul>
        <ng-template #ordered>
          <ol>
            <li *ngFor="let item of block.data.items" [innerHTML]="item"></li>
          </ol>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
  <thema-core-share-section></thema-core-share-section>
</div>
<ed-carousel></ed-carousel>
