<section class="share-section" aria-labelledby="share-header" *transloco="let t">
  <header id="share-header"></header>
  <button class="share-button" *ngIf="supportsShare; else staticText" (click)="onShare()">
    {{ t('product_shareButton') }}
    <svg-icon key="shareArrow" fontSize="24px"></svg-icon>
  </button>
  <ng-template #staticText>{{t('product_shareButton')}}:&nbsp;</ng-template>
  <a
    thema-core-icon-button="facebook"
    title="Udostępnij na Facebooku"
    iconSize="24px"
    target="_blank"
    rel="nofollow noopener noreferrer"
    href="https://www.facebook.com/sharer.php?u={{ url }}"
  ></a>
  <button title="skopiuj do schowka" thema-core-icon-button="share" (click)="copyToClipboard()"></button>
</section>
