import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarLinkComponent } from './navbar-link.component';
import { HoverMenuModule } from '../hover-menu/hover-menu.module';
import { SvgIconsModule } from '../../svg/_svg-icon/svg-icons.module';
import { RouterLinkByUrlModule } from '@thema-core/directives';
import { TranslocoModule } from '@ngneat/transloco';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { SlideInModule } from '../slide-in/slide-in.module';

@NgModule({
  declarations: [NavbarLinkComponent],
  exports: [NavbarLinkComponent],
  imports: [
    CommonModule,
    SlideInModule,
    SvgIconsModule,
    HoverMenuModule,
    RouterLinkByUrlModule,
    TranslocoModule,
    IconButtonModule,
  ],
})
export class NavbarLinkModule {}
