import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SnackService } from '@thema-core/services';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { ENVIRONMENT } from '@thema-core/tokens';
import { Router } from '@angular/router';
import { SvgIconsModule } from '../svg';
import { ButtonModule } from '../simple-components';
import { IconButtonModule } from '../simple-components/icon-button/icon-button.module';
import { NgIf } from '@angular/common';

@Component({
  selector: 'thema-core-share-section',
  templateUrl: './share-section.component.html',
  styleUrls: ['./share-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, SvgIconsModule, IconButtonModule, ButtonModule, NgIf],
})
export class ShareSectionComponent {
  private readonly snack = inject(SnackService);
  private readonly env = inject(ENVIRONMENT);
  private readonly router = inject(Router);
  private t = inject(TranslocoService);

  supportsShare = typeof navigator.share === 'function';
  url = `${this.env.clientUrl}${this.router.url}`;

  onShare(): void {
    if (this.supportsShare && navigator.canShare({ url: this.url })) {
      navigator.share({ url: this.url }).catch(() => this.copyToClipboard());
    } else {
      this.copyToClipboard();
    }
  }

  copyToClipboard(): void {
    navigator.clipboard
      .writeText(this.url)
      .then(() => {
        this.snack.showInfo(this.t.translate('common_copiedToClipboard'));
      })
      .catch(() => this.snack.showError(this.t.translate('common_cannotPerformAction')));
  }
}
