import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionsContactComponent } from './questions-contact/questions-contact.component';
import { SvgIconsModule } from '../svg/_svg-icon/svg-icons.module';
import { TranslocoModule } from '@ngneat/transloco';
import { DiamondAttributeBoxComponent } from './diamond-visual-guide/diamond-attribute-box/diamond-attribute-box.component';
import { DiamondClarityGuideComponent } from './diamond-visual-guide/diamond-clarity-guide/diamond-clarity-guide.component';
import { DiamondColorGuideComponent } from './diamond-visual-guide/diamond-color-guide/diamond-color-guide.component';
import { DiamondVisualGuideComponent } from './diamond-visual-guide/diamond-visual-guide.component';
import { DiamondCertificateLinkComponent } from './diamond-certificate-link/diamond-certificate-link.component';
import { IconButtonModule } from '../simple-components/icon-button/icon-button.module';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '../tooltip/tooltip-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { GetRouteModule, TypeofModule } from '@thema-core/pipes';
import { AutoResizeModule } from '@thema-core/directives';
import { FormFieldModule, QuantityControlModule } from '../simple-components';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    QuestionsContactComponent,
    DiamondAttributeBoxComponent,
    DiamondClarityGuideComponent,
    DiamondColorGuideComponent,
    DiamondVisualGuideComponent,
    DiamondCertificateLinkComponent,
  ],
  imports: [
    CommonModule,
    SvgIconsModule,
    TranslocoModule,
    IconButtonModule,
    QuantityControlModule,
    FormsModule,
    MatTooltipModule,
    FormFieldModule,
    NgSelectModule,
    TypeofModule,
    AutoResizeModule,
    RouterModule,
    GetRouteModule,
  ],
  exports: [
    QuestionsContactComponent,
    DiamondAttributeBoxComponent,
    DiamondClarityGuideComponent,
    DiamondColorGuideComponent,
    DiamondVisualGuideComponent,
    DiamondCertificateLinkComponent,
  ],
})
export class ProductLibModule {}
