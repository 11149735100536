import {
  ChangeDetectionStrategy,
  Component,
  Input,
  SecurityContext,
} from '@angular/core';
import { OutputData } from '@editorjs/editorjs';

@Component({
  selector: 'thema-core-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomLayoutComponent {
  public parsedContent: OutputData;

  @Input()
  public set content(value: string | OutputData) {
    this.parsedContent = this.parseContent(value);
  }

  private parseContent(content: string | OutputData): OutputData {
    if (this.isOutputData(content)) {
      return content;
    }

    try {
      return JSON.parse(content);
    } catch (_) {
      return { blocks: [] } as OutputData;
    }
  }

  private isOutputData(content: string | OutputData): content is OutputData {
    return typeof content === 'object' && 'time' in content;
  }

  protected readonly SecurityContext = SecurityContext;
}
